import React, { useEffect, useState } from "react";
import {
  CardBody,
  CardSubtitle,
  CardText,
  CardTitle,
  Col,
  Container,
  Row,
} from "reactstrap";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./bodysection.css";
import { Button, Form, FormGroup, Label, Input } from "reactstrap";
import { contactUs } from "../../Utility/api";
import { callApi } from "../../Utility/axios";
import Loader from "../Loader";

function Contactus() {
  const [loader, setLoader] = useState(false);
  const MsgForm = () => {
    const handleSubmit = async (e) => {
      setLoader(true);
      e.preventDefault();
      const formData = Object.fromEntries(new FormData(e.target));
      const request = {
        ...contactUs,
        payload: formData,         
      };

      const [err, data] = await callApi(request);
      if (!err && data && data.code === "OK") {
        toast.success(data?.message);
        setLoader(false);
        document.querySelector('button[type="reset"]').click();
      } else {
        toast.error("Failed to send the message. Please try again.");
        setLoader(false);
      }
    };

    useEffect(() => {
      document.title = "Contact Us - Get in Touch with Arjiv Exports"
    }, [])

    return (
      <Form onSubmit={handleSubmit}>
        {loader && <Loader />}
        <ToastContainer />
        <Row style={{ gap: 10 }}>
          <Col className="px-0">
            <FormGroup className="text-left">
              <Label for="Name">Name *</Label>
              <Input
                type="text"
                name="name"
                placeholder="Full Name"
                required
              />
            </FormGroup>
          </Col>
          <Col className="px-0">
            <FormGroup className="text-left">
              <Label for="Email">Email *</Label>
              <Input
                type="email"
                name="email"
                id="Email"
                placeholder="your@email.com"
                required
              />
            </FormGroup>
          </Col>
        </Row>
        <FormGroup className="text-left">
          <Label for="subject">Subject *</Label>
          <Input
            type="text"
            name="subject"
            id="subject"
            placeholder="Subject"
            required
          />
        </FormGroup>
        <FormGroup className="text-left">
          <Label for="textarea">Message *</Label>
          <textarea
            className="form-control"
            rows={7}
            type="textarea"
            name="message"
            placeholder="Write Your Message"
            required
          />
        </FormGroup>

        <Button type="submit" className="mb-lg-5 mb-4 w-100">Send Your Message</Button>
        <Button className="d-none" type="reset">reset</Button>
      </Form>
    );
  };

  return (
    <div className="main-content">
      <Container className="py-5 main-content">
        <Row xs="1" sm="1" md="2">
          <Col className="">
            <CardTitle className="title">CONTACT FORM</CardTitle>
            <MsgForm />
            <div>
              <iframe
                width="100%"
                height="400"
                frameborder="0"
                scrolling="no"
                marginheight="0"
                marginwidth="0"
                src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=Vasta%20Devdi%20Rd,%20Tunki,%20Katargam,%20Surat,%20Gujarat%20395004+(arjiv%20Exports)&amp;t=k&amp;z=16&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
              ></iframe>
            </div>
          </Col>
          <Col>
            <CardTitle className="title">CONTACT INFO</CardTitle>
            <CardBody>
              <CardSubtitle className="title2">WHERE TO REACH US</CardSubtitle>
              <CardText className="contacttext">
                You can reach us at the following <br /> Address: CC-7085, Bharat Diamond Bourse, BKC, Bandra East,
                Mumbai, Maharashtra 400051
              </CardText>
              <CardSubtitle className="title2">EMAIL US</CardSubtitle>
              <CardText className="contacttext">
                Email your issues and suggestion for the following
                <br /> Email Address: <a href="mailto:info@arjiv.com">info@arjiv.com</a>
              </CardText>
              <CardSubtitle className="title2">NEED TO CALL US?</CardSubtitle>
              <CardText className="contacttext">
                From Monday to Friday,10:00 AM - 8:00 PM,
                <br /> Call Us at: <a href="tel:+912223630651">+91 22 23630651</a>
              </CardText>
              <CardSubtitle className="title2">
                WE HAVE GREAT SUPPORT
              </CardSubtitle>
              <CardText className="contacttext">
                We provide the best Quality of products to you.We are always
                here to help our lovely customers.We ship our products anywhere
                with more secure. We provide the best Quality of products to
                you.
              </CardText>
            </CardBody>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Contactus;
